import { TotalHoursValue } from "src/app/features/timesheets/interfaces/total-hours-values.model";
import { SelectOption } from "src/app/shared/interfaces/select-option.interface";
import { Pier } from "../../features/admin/piers/models/pier.model";
import { Port } from "../../features/admin/ports/models/port.model";
import { Terminal } from "../../features/admin/terminals/models/terminal.model";

export const PAG_MAX_RESULTS = 10;
export const CERTIFICATIONS_MISSING_ERROR = "Maritime:CERT004";
export const ZERO_ASSIGNMENTS_MISSING_ERROR = "Maritime:DO_001";
export const SAME_START_TIME_MISSING_ERROR = "Maritime:WORKERS_004";
export const IS_ASSIGNED_MISSING_ERROR = "Maritime:WORKERS_003";

export const getParamsFromFilters = filters => {
	if (filters) {
		let params = "";
		const entries = Object.entries(filters);

		if (entries.length > 0) {
			params = "&";
		}

		for (let [key, value] of entries) {
			if (value !== "") {
				params += `${key}=${value}&`;
			}
		}

		params = params.slice(0, -1);

		return params;
	} else {
		return "";
	}
};

export const objectHasValues = (object: object) => {
	if (!object) return false;

	let result = false;
	Object.entries(object).forEach(entry => {
		if (entry[1] !== "") {
			result = true;
		}
	});
	return result;
};

export const getUpdatedItems = (subject: Port | Terminal | Pier | any, collection: Array<any>, reference: string = 'id') => {
	return collection.map(item => {
		if (item[reference] === subject[reference]) {
			return subject;
		} else {
			return item;
		}
	});
};

export const getFilterData = (data, filterName) => {
	if (data.availableFilters?.length) {
		const filtersArray = data.availableFilters.filter(filter => {
			return filter.filterName === filterName;
		});
		return filtersArray[0].filterData;
	}
};

export const filterInList = (list, value): boolean => {
	return list.some(item => item.value === value);
};

export const filterTimesheetEntry = (entries, filter) => {
	return entries.filter(entry => {
		return entry.rate.code === filter;
	})[0];
};

const mapEntries = entries => {
	return {
		stHours: filterTimesheetEntry(entries, "ST"),
		dsHours: filterTimesheetEntry(entries, "DS"),
		otHours: filterTimesheetEntry(entries, "OT"),
		doHours: filterTimesheetEntry(entries, "DO"),
		fxHours: filterTimesheetEntry(entries, "FX"),
		mtHours: filterTimesheetEntry(entries, "MT"),
		dmHours: filterTimesheetEntry(entries, "DM"),
    ddstHours: filterTimesheetEntry(entries, "DDST"),
    ddotHours: filterTimesheetEntry(entries, "DDOT")
	};
};

const mapTime = (timeIn, timeOut) => {
	let result = { in: null, out: null };

	if (timeIn) {
		timeIn = timeIn.replace("Z", "");
		result.in = timeIn.substring(0, timeIn.length - 3);
	}
	if (timeOut) {
		timeOut = timeOut.replace("Z", "");
		result.out = timeOut.substring(0, timeOut.length - 3);
	}

	return result;
};

export const mapTimesheetRow = row => {
	const timesheetEntries = mapEntries(row.timeSheetEntries);
	const time = mapTime(row.timeIn, row.timeOut);

	return {
		...row,
		timeIn: time.in,
		timeOut: time.out,
		jobCodeId: row.jobCode?.id,
		jobCodeCode: row.jobCode?.code,
		trainedJobCode: row.trainedJobCode,
		workerName: `${row.worker.firstName} ${row.worker.lastName}`,
		currentStatus: row.currentStatus,
		currentStatusReason: row.currentStatusReason,
		workerId: row.worker.id,
		workerCardNumber:
			row.worker.workerUnions.length > 0 ? row.worker.workerUnions[0].cardNumber : null,
		stHours: timesheetEntries?.stHours?.hours || null,
		dsHours: timesheetEntries?.dsHours?.hours || null,
		otHours: timesheetEntries?.otHours?.hours || null,
		doHours: timesheetEntries?.doHours?.hours || null,
		fxHours: timesheetEntries?.fxHours?.hours || null,
		mtHours: timesheetEntries?.mtHours?.hours || null,
		dmHours: timesheetEntries?.dmHours?.hours || null,
    ddstHours: timesheetEntries?.ddstHours?.hours || null,
    ddotHours: timesheetEntries?.ddotHours?.hours || null,
		pi: row.pi || null,
		npi: row.npi || null,
		timeSheetEntries: timesheetEntries,
		highExpRate: row.highExpRate,
		highSTRate: row.highSTRate,
		rowDifferentialId: row.rowDifferentialId,
		entriesTotalHours: row.entriesTotalHours
	};
};

export const mapTotalHoursTimesheet = hourRateTotals => {
	if (hourRateTotals)
		return {
			stTotalHours: hourRateTotals["ST"] ? hourRateTotals["ST"] : 0,
			dsTotalHours: hourRateTotals["DS"] ? hourRateTotals["DS"] : 0,
			otTotalHours: hourRateTotals["OT"] ? hourRateTotals["OT"] : 0,
			doTotalHours: hourRateTotals["DO"] ? hourRateTotals["DO"] : 0,
			fxTotalHours: hourRateTotals["FX"] ? hourRateTotals["FX"] : 0,
			mtTotalHours: hourRateTotals["MT"] ? hourRateTotals["MT"] : 0,
      dmTotalHours: hourRateTotals["DM"] ? hourRateTotals["DM"] : 0,
      ddstTotalHours: hourRateTotals["DDST"] ? hourRateTotals["DDST"] : 0,
      ddotTotalHours: hourRateTotals["DDOT"] ? hourRateTotals["DDOT"] : 0,
			piTotalHours: hourRateTotals["PI"] ? hourRateTotals["PI"] : 0,
			npiTotalHours: hourRateTotals["NPI"] ? hourRateTotals["NPI"] : 0
		} as TotalHoursValue;
	else return null;
};

export const mapTimesheet = timesheet => {
	var hourRateTotals: TotalHoursValue = mapTotalHoursTimesheet(timesheet.hourRateTotals);
	return {
		...timesheet,
		hourRateTotals: hourRateTotals,
		rows: timesheet.rows?.map(row => {
			return mapTimesheetRow(row);
		})
	};
};

export const getPaginationValues = pageEvent => {
	return {
		skipCount: pageEvent.pageIndex * pageEvent.pageSize,
		maxResults: pageEvent.pageSize
	};
};

export const isMac = () => {
	return navigator.platform.indexOf("Mac") > -1;
};

export const getErrorMessage = error => {
	switch (error.status) {
		case 403:
			return error?.error?.error?.message;
		case 400:
			return error.message;
		default:
			return "Server Error. Please try again later.";
	}
};

export const parseDateTime = time => {
	if (time) {
		return time.replace(/(..)/g, "$1:").slice(0, -1);
	} else {
		return time;
	}
};
export const uncolonTime = time => {
	if (time) {
		return time.replace(/:/, "");
	} else {
		return time;
	}
};

export const TimeSheetStatesWorked: Array<SelectOption> = [
	{ text: "Draft", value: false },
	{ text: "Worked", value: true },
];

export const setStartTime = (value) => {
	//@TODO Remove this when the old templates are updated
	//For templates that uses colon in time format
	const timeHasColon = value.startTime.includes(":");
	if (timeHasColon) value.startTime = uncolonTime(value.startTime);

	if (value.startTime.split("")[0] === "0") {
		value.startDate.setHours(parseInt(value.startTime.split("")[1]));
	} else {
		value.startDate.setHours(
			parseInt(value.startTime.split("")[0] + value.startTime.split("")[1])
		);
	}

	value.startDate.setMinutes(
		parseInt(value.startTime.split("")[2] + value.startTime.split("")[3])
	);

	let userTimezoneOffset = value.startDate.getTimezoneOffset() * 60000;
	value.startDate = new Date(value.startDate.getTime() - userTimezoneOffset);

	return value;
}

export const enum2Array = (element) => {
	return Object.keys(element)
		.filter((value) => isNaN(Number(value)))
		.map((text) => {
			return {
				value: element[text as keyof typeof element],
				text
			};
		});
};

export function cleanObject(obj) {
  const cleanedObj = {};

  for (const [key, value] of Object.entries(obj)) {
    if (value != null && value !== '') {
      cleanedObj[key] = value;
    }
  }

  return cleanedObj;
}
